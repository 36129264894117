<template>
    <div v-if="filters">
        <v-section-divider>
            General Filters
        </v-section-divider>

        <div v-if="filters" class="grid grid-cols-1 gap-4 p-default">
            <v-form-select
                v-if="organizations.length > 2"
                v-model="filters.organization_id"
                :options="selectOptions(organizations, 'name', 'organization_id')"
                default-label="All Organizations"
            />

            <v-form-select
                v-model="filters.user_type_id"
                :options="userTypeOptions"
                default-label="All User Types"
            />

            <v-form-select
                v-model="filters.role_id"
                :options="roleOptions"
                default-label="All Roles"
            />

            <v-form-select
                v-model="filters.is_approved"
                :options="approvalStatusOptions"
                default-label="All Approvals"
            />

            <v-form-select
                v-model="filters.is_login_enabled"
                :options="loginOptions"
                default-label="All Logins"
            />
        </div>
    </div>
</template>

<script>
import TableFiltersMixin from "@/mixins/table-filters";
import RoleService from "@/services/modules/role-service";
import UserTypeService from "@/services/modules/user-type-service";
import UserService from "@/services/modules/user-service";

export default {
    name: "UserTableFilters",
    mixins: [
        TableFiltersMixin,
    ],
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {
                /**
                 * @todo Write a description.
                 */
                role_id: this.$route.query.role_id ?? null,

                /**
                 * @todo Write a description.
                 */
                user_type_id: this.$route.query.user_type_id ?? null,

                /**
                 * @todo Write a description.
                 */
                organization_id: this.$route.query.organization_id ?? null,

                /**
                 * @todo Write a description.
                 */
                is_approved: this.$route.query.is_approved ?? null,

                /**
                 * @todo Write a description.
                 */
                is_login_enabled: this.$route.query.is_login_enabled ?? null,
            },

            /**
             * @todo Write a description.
             */
            roleOptions: [],

            /**
             * @todo Write a description.
             */
            userTypeOptions: [],

            /**
             * @todo Write a description.
             */
            organizations: [],

            /**
             * @todo Write a description.
             */
            approvalStatusOptions: [
                {
                    label: "All Approvals",
                    value: null,
                },
                {
                    label: "Approved",
                    value: "1",
                },
                {
                    label: "Pending Approval",
                    value: "0",
                },
            ],

            /**
             * @todo Write a description.
             */
            loginOptions: [
                {
                    label: "All Logins",
                    value: null,
                },
                {
                    label: "Login Enabled",
                    value: "1",
                },
                {
                    label: "Login Disabled",
                    value: "0",
                },
            ],
        };
    },
    created() {
        RoleService.index()
            .then((response) => {
                const parsedResponse = response.data.data
                    .map(({ name: label, role_id: value }) => ({ label, value }));

                this.roleOptions = [
                    { label: "All Roles", value: null },
                    ...parsedResponse,
                ];
            });

        UserTypeService.index()
            .then((response) => {
                const parsedResponse = response.data.data
                    .map(({ name: label, user_type_id: value }) => ({ label, value }));

                this.userTypeOptions = [
                    { label: "All User Types", value: null },
                    ...parsedResponse,
                ];
            });

        UserService.getOrganizations(this.$me.getUser().user_id)
            .then((response) => {
                this.organizations = [
                    {
                        name: "All Organizations",
                        organization_id: null,
                    },
                    ...response.data.data,
                ];
            });
    },
    methods: {
        clearFilters() {
            this.filters = {};
        },
    },
};
</script>
