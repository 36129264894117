var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-model-table',_vm._b({attrs:{"columns":_vm.columns,"query":_vm.query},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_vm._v(" "+_vm._s(_vm.tableDataSource)+" "),_vm._t("filters")]},proxy:true},{key:"column_name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('v-link',{attrs:{"text-alignment":"left","to":{ name: 'users.show', params: { user: row.user_id } }}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"flex items-center space-x-1"},[_c('v-text',{staticClass:"text-gray-500",class:row.email ? '' : 'italic'},[_vm._v(" "+_vm._s(row.email ? row.email : "No Email")+" ")])],1)],1)]}},{key:"column_roles",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('v-text',[_vm._v(" "+_vm._s(row.user_type ? row.user_type.name : "-")+" ")]),_c('v-text',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(row.roles ? row.roles.map(function (role) { return role.name; }).join(", ") : "-")+" ")])],1)]}},{key:"column_is_approved",fn:function(ref){
var row = ref.row;
return [(row.is_approved)?[_c('svg',{staticClass:"h-5 w-5 mx-auto text-green-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","clip-rule":"evenodd"}})])]:[_c('svg',{staticClass:"h-5 w-5 mx-auto text-gray-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z","clip-rule":"evenodd"}})])]]}},{key:"column_is_login_enabled",fn:function(ref){
var row = ref.row;
return [(row.is_login_enabled)?[_c('svg',{staticClass:"h-5 w-5 mx-auto text-green-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","clip-rule":"evenodd"}})])]:[_c('svg',{staticClass:"h-5 w-5 mx-auto text-gray-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z","clip-rule":"evenodd"}})])]]}},{key:"column_impersonate",fn:function(ref){
var row = ref.row;
return [_c('v-link',{staticClass:"flex justify-center",attrs:{"disabled":_vm.isLoading || !row.is_login_enabled,"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.impersonateUser(row.user_id)}}},[_c('v-icon',{staticClass:"fa fa-user-secret text-secondary-500"})],1)]}}],null,true)},'v-model-table',_vm.tableDataSource,false))}
var staticRenderFns = []

export { render, staticRenderFns }