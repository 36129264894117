<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section-heading>
                <template v-slot:default>
                    Filters
                </template>

                <template v-slot:actions>
                    <div v-if="hasFilters">
                        <v-link color="primary" @click="clearFilters()">
                            Clear
                        </v-link>
                    </div>
                </template>
            </v-section-heading>

            <v-user-table-filters ref="userTableFilters" v-model="filters" />
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section-heading>
                <template v-slot:default>
                    Users
                </template>

                <template v-slot:description>
                    A list of all the users in the application.
                </template>

                <template v-slot:actions>
                    <div class="block lg:hidden">
                        <v-button color="primary" @click.stop="toggleSidebar">
                            <i class="fa fa-filter mr-2" />
                            Filter Results
                        </v-button>
                    </div>
                </template>
            </v-section-heading>

            <v-user-table :query="filters" />
        </template>
    </v-main>
</template>

<script>
import UserTable from "@/components/application/user/user-table.vue";
import UserTableFilters from "@/components/application/user/user-table-filters.vue";
import Main from "@/layouts/panel/main.vue";

export default {
    components: {
        "v-user-table": UserTable,
        "v-user-table-filters": UserTableFilters,
        "v-main": Main,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {},
        };
    },
    computed: {
        hasFilters() {
            return this.parseFilters(this.filters).length;
        },
    },
    created() {
        // Filter users by the query string parameters on initial page load.
        this.filters = {
            ...this.$route.query,
            is_approved: this.$route.query.is_approved ? `${this.$route.query.is_approved}` : null,
            is_login_enabled: this.$route.query.is_login_enabled ? `${this.$route.query.is_login_enabled}` : null,
        };
    },
    methods: {
        clearFilters() {
            this.$refs.userTableFilters.clearFilters();
        },
    },
};
</script>
