<template>
    <v-model-table v-bind="tableDataSource" :columns="columns" :query="query">
        <template v-slot:filters>
            {{ tableDataSource }}
            <slot name="filters" />
        </template>

        <template v-slot:column_name="{ row }">
            <div>
                <v-link text-alignment="left" :to="{ name: 'users.show', params: { user: row.user_id } }">
                    {{ row.name }}
                </v-link>

                <div class="flex items-center space-x-1">
                    <v-text class="text-gray-500" :class="row.email ? '' : 'italic'">
                        {{ row.email ? row.email : "No Email" }}
                    </v-text>
                </div>
            </div>
        </template>

        <template v-slot:column_roles="{ row }">
            <div>
                <v-text>
                    {{ row.user_type ? row.user_type.name : "-" }}
                </v-text>

                <v-text class="text-gray-500">
                    {{ row.roles ? row.roles.map((role) => role.name).join(", ") : "-" }}
                </v-text>
            </div>
        </template>

        <template v-slot:column_is_approved="{ row }">
            <template v-if="row.is_approved">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto text-green-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </template>

            <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </template>
        </template>

        <template v-slot:column_is_login_enabled="{ row }">
            <template v-if="row.is_login_enabled">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto text-green-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
            </template>

            <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </template>
        </template>

        <template v-slot:column_impersonate="{ row }">
            <v-link class="flex justify-center" :disabled="isLoading || !row.is_login_enabled" href="#" @click.stop="impersonateUser(row.user_id)">
                <v-icon class="fa fa-user-secret text-secondary-500" />
            </v-link>
        </template>
    </v-model-table>
</template>

<script>
import AuthService from "@/services/modules/auth-service";

export default {
    name: "UserTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        service: {
            type: Object,
            required: false,
            default: () => null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            userTypes: null,
        };
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "userModule",
            };
        },
        columns() {
            const tableColumns = {
                name: {
                    label: "Name",
                    fullWidth: false,
                },
                roles: {
                    label: "User Type / Role",
                    formatter: (row) => (row.roles ? row.roles.map((role) => role.name).join(", ") : []),
                    fullWidth: false,
                },
                // user_type: {
                //     label: "User Type",
                //     formatter: (row) => (row.user_type ? row.user_type.name : ""),
                //     fullWidth: false,
                // },
                is_approved: {
                    label: "Approved",
                    formatter: (row) => (row.is_approved ? "Yes" : "No"),
                    fullWidth: false,
                    align: "center",
                },
                is_login_enabled: {
                    label: "Login",
                    formatter: (row) => (row.is_login_enabled ? "Yes" : "No"),
                    fullWidth: false,
                    align: "center",
                },
                impersonate: {
                    label: "Impersonate",
                    fullWidth: false,
                    align: "center",
                },
            };

            return tableColumns;
        },
    },
    created() {
        this.$store.dispatch("userTypeModule/fetchAllItems", this.query)
            .then((response) => {
                this.userTypes = response.data;
            })
            .catch((error) => {
                alert(error.response.data.message);
            });
    },
    methods: {
        impersonateUser(userId) {
            this.toggleLoading();

            AuthService.impersonateUser(userId)
                .catch((error) => {
                    alert(error.response.data.message);
                })
                .then((response) => {
                    localStorage.setItem("impersonation_token", response.data.impersonation_token);
                })
                .finally(() => {
                    this.toggleLoading();

                    // cannot use router push because page needs to reload to refresh user
                    window.location.href = this.$router.resolve({ name: "home" }).href;
                });
        },
    },
};
</script>
